import React from 'react';
import '../App.scss';

export const ContactCard: React.FC = () => {
    return (
        <div className='contact-card'>
            <section className='about'>
                <p>
                    Rocket Mobile RV Service will come to you when your RV
                    requires repair. With 20 years of experience, certification
                    in both RVIA and ASE, you will rest assured knowing that the
                    work has been done right the first time.
                </p>
            </section>
            <section className='details'>
                <p className='phone'>
                    Phone:{' '}
                    <span>
                        <a href='tel:480-269-6466'>480-269-6466</a>
                    </span>
                </p>
                <p className='email'>
                    Email:{' '}
                    <span>
                        <a href='mailto:rocketrvrene@gmail.com'>
                            rocketrvrene@gmail.com
                        </a>
                    </span>
                </p>
            </section>
        </div>
    );
};
