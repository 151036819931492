import React from 'react';
import rocket from '../svgs/rocketLogoAzColors.svg';
import leftRv from '../svgs/RV-Red.svg';
import '../App.scss';

export const Welcome: React.FC = () => {
    // const [isRolling, setIsRolling] = useState(false);
    return (
        <header className='Welcome-header'>
            <h1>Rocket Mobile RV Service</h1>
            <img src={rocket} className='Rocket' alt='Big Rocket' />
            {/* <img src={leftRv} alt='leftRv' /> */}
        </header>
    );
};
