import React from 'react';
import './App.scss';

import { ContactCard } from './contactCard';
import { Welcome } from './welcome';

function App() {
    return (
        <div className='App'>
            <Welcome />
            <ContactCard />
        </div>
    );
}

export default App;
